import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";

function Faq() {
  const navigate = useNavigate();
  const [faqData, setFaqData] = useState([]);

  const handleBackClick = () => {
    navigate("/");
  };

  useEffect(() => {
    // Fetch FAQ data from API
    fetch("https://shreddersbay.com/API/faqs_api.php?action=select")
      .then((response) => response.json())
      .then((data) => setFaqData(data))
      .catch((error) => console.error("Error fetching Faq data:", error));
  }, []);

  return (
    <Box
      sx={{
        marginTop: { xs: 8, sm: 8, md: 8 },
        marginBottom: { xs: 4, sm: 0, md: 0 },
      }}
    >
      {/* Back Button */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "10px",
          backgroundColor: "#f5f5f5",
          marginTop: 6,
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <IconButton onClick={handleBackClick} sx={{ marginLeft: "10px" }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography
          variant="h6"
          component="div"
          sx={{ fontWeight: "bold", marginLeft: "10px" }}
        >
          Frequently Asked Questions
        </Typography>
      </Box>

      {/* FAQ Section */}
      <Box
        sx={{
          maxWidth: "800px",
          margin: "20px auto",
          padding: "20px",
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        {faqData.length > 0 ? (
          faqData.map((item) => (
            <Accordion key={item.id} sx={{ marginBottom: "10px" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  backgroundColor: "#f9f9f9",
                  fontWeight: "bold",
                }}
              >
                <Typography>{item.question}</Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  backgroundColor: "#f5f5f5",
                  color: "#555",
                }}
              >
                <Typography>{item.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            Loading FAQs...
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default Faq;
