import React from 'react';
import { motion } from 'framer-motion';
import { ImagesApp } from '../../Images/ImagesApp';

function NewYearBanner() {

     // const containerStyle = {
     //      display: 'flex',
     //      flexDirection: 'column',
     //      alignItems: 'center',
     //      justifyContent: 'center',
     //      height: '70vh',
     //      backgroundImage: `url(${ImagesApp.newYear})`,
     //      backgroundSize: 'cover',
     //      backgroundPosition: 'center',
     //      backgroundRepeat: 'no-repeat',
     // };

     // // Mobile-specific styles
     // const mobileStyle = {
     //      display: 'flex',
     //      flexDirection: 'column',
     //      alignItems: 'center',
     //      justifyContent: 'center',
     //      height: '30vh',
     //      backgroundImage: `url(${ImagesApp.newYear})`,
     //      backgroundSize: 'cover',
     //      backgroundPosition: 'center',
     //      backgroundRepeat: 'no-repeat',
     // };

     const isMobile = window.innerWidth <= 768; // Check if screen width is less than 768px
     return (
          <div >



               <div
                    style={{
                         display: 'flex',
                         flexDirection: 'column',
                         alignItems: 'center',
                         justifyContent: 'center',
                         height: isMobile ? '50vh' : '70vh', // Reduce height on mobile
                         backgroundImage: `url(${ImagesApp.newYear})`,
                         backgroundSize: 'cover',
                         backgroundPosition: 'center',
                         backgroundRepeat: 'no-repeat',
                    }}
               >
                    <motion.h1
                         initial={{ opacity: 0, scale: 1.5 }}
                         animate={{ opacity: 1, scale: 1 }}
                         transition={{ duration: 1.5, ease: "easeOut" }}
                         style={{
                              color: '#ffdf00',
                              textShadow: '0px 0px 10px #ff8c00',
                              fontSize: 'clamp(1rem, 5vw, 4rem)',
                              textAlign: 'center',
                              marginTop: isMobile ? 80 : 20,
                             
                         }}
                    >
                         🎉 Happy New Year! 2025 🎉
                    </motion.h1>

                    <motion.div
                    initial={{ y: 100 }}
                    animate={{ y: 0 }}
                    transition={{
                         repeat: Infinity,
                         repeatType: 'reverse',
                         duration: 2,
                         ease: 'easeInOut',
                    }}
                    style={{ marginTop: '20px' }}
               >
                    <motion.img
                         src={ImagesApp.bottleShimmer}
                         alt="Star"
                         style={{
                              width: '150px', // Updated width
                              height: '150px', // Added height for consistent scaling
                              margin: '0 10px',
                         }}
                         animate={{ rotate: 360 }}
                         transition={{
                              repeat: Infinity,
                              repeatType: 'loop',
                              duration: 4,
                              ease: 'linear',
                         }}
                    />
                    <motion.img
                         src={ImagesApp.bottleShimmer}
                         alt="Star"
                         style={{
                              width: '150px', // Updated width
                              height: '150px', // Added height for consistent scaling
                              margin: '0 10px',
                         }}
                         animate={{ rotate: -360 }}
                         transition={{
                              repeat: Infinity,
                              repeatType: 'loop',
                              duration: 4,
                              ease: 'linear',
                         }}
                    />


               </motion.div>


               <div
                    style={{
                         position: 'relative', // Parent container for absolute positioning
                         height: '100vh', // Full height of the viewport
                         width: '100%', // Full width of the viewport
                         display: 'flex',
                         alignItems: 'center', // Vertically center the balloons
                         justifyContent: 'center',
                    }}
               >
                    {/* Left Balloon */}
                    <motion.div
                         initial={{ y: 500, x: -100 }} // Start off-screen to the left
                         animate={{ y: 0 }}
                         transition={{
                              repeat: Infinity,
                              repeatType: 'reverse',
                              duration: 2,
                              ease: 'linear',
                         }}
                         style={{
                              position: 'absolute', // Absolute positioning
                              right: isMobile ? '5%': '10%', // Place near the right side
                         }}
                    >
                         <motion.img
                              src="https://parspng.com/wp-content/uploads/2022/01/balloonpng.parspng.com-5.png"
                              alt="Balloon Left"
                              style={{
                                   width: isMobile ? '50px': '150px',
                                   width: isMobile ? '50px': '150px',
                              }}
                              animate={{ rotate: 200 }}
                              transition={{
                                   repeat: Infinity,
                                   repeatType: 'loop',
                                   duration: 5,
                                   ease: 'linear',
                              }}
                         />
                    </motion.div>

                    {/* Right Balloon */}
                    <motion.div
                         initial={{ y: 500, x: 100 }} // Start off-screen to the right
                         animate={{ y: 0 }}
                         transition={{
                              repeat: Infinity,
                              repeatType: 'reverse',
                              duration: 2,
                              ease: 'easeInOut',
                         }}
                         style={{
                              position: 'absolute', // Absolute positioning
                              right: isMobile ? '5%': '10%', // Place near the right side
                         }}
                    >
                         <motion.img
                              src="https://parspng.com/wp-content/uploads/2022/01/balloonpng.parspng.com-5.png"
                              alt="Balloon Right"
                              style={{
                                   width: isMobile ? '50px': '150px',
                                   width: isMobile ? '50px': '150px',
                              }}
                              animate={{ rotate: -360 }}
                              transition={{
                                   repeat: Infinity,
                                   repeatType: 'loop',
                                   duration: 10,
                                   ease: 'easeInOut',
                              }}
                         />
                    </motion.div>
               </div>



               <div
                    style={{
                         position: 'relative', // Parent container for absolute positioning
                         height: '100vh', // Full height of the viewport
                         width: '100%', // Full width of the viewport
                         display: 'flex',
                         alignItems: 'center', // Vertically center the balloons
                         justifyContent: 'center',
                    }}
               >
                    {/* Left Balloon */}
                    <motion.div
                         initial={{ y: 500, x: -100 }} // Start off-screen to the left
                         animate={{ y: 0 }}
                         transition={{
                              repeat: Infinity,
                              repeatType: 'reverse',
                              duration: 2,
                              ease: 'linear',
                         }}
                         style={{
                              position: 'absolute', // Absolute positioning
                              left: isMobile ? '5%': '10%', // Place near the right side
                         }}
                    >
                         <motion.img
                              src="https://parspng.com/wp-content/uploads/2022/01/balloonpng.parspng.com-5.png"
                              alt="Balloon Left"
                              style={{
                                   width: isMobile ? '50px': '150px',
                                   width: isMobile ? '50px': '150px',
                              }}
                              animate={{ rotate: 200 }}
                              transition={{
                                   repeat: Infinity,
                                   repeatType: 'loop',
                                   duration: 5,
                                   ease: 'linear',
                              }}
                         />
                    </motion.div>

                    {/* Right Balloon */}
                    <motion.div
                         initial={{ y: 500, x: 100 }} // Start off-screen to the right
                         animate={{ y: 0 }}
                         transition={{
                              repeat: Infinity,
                              repeatType: 'reverse',
                              duration: 2,
                              ease: 'easeInOut',
                         }}
                         style={{
                              position: 'absolute', // Absolute positioning
                              left: isMobile ? '5%': '10%', // Place near the right side
                         }}
                    >
                         <motion.img
                              src="https://parspng.com/wp-content/uploads/2022/01/balloonpng.parspng.com-5.png"
                              alt="Balloon Right"
                              style={{
                                   width: isMobile ? '50px': '150px',
                                   width: isMobile ? '50px': '150px',
                              }}
                              animate={{ rotate: -360 }}
                              transition={{
                                   repeat: Infinity,
                                   repeatType: 'loop',
                                   duration: 10,
                                   ease: 'easeInOut',
                              }}
                         />
                    </motion.div>
               </div>
               </div>



           



               {/* Fireworks */}
               {/* <motion.div
                         style={{ marginTop: '50px', display: 'flex', justifyContent: 'space-around', width: '100%' }}
                         initial={{ opacity: 0, scale: 0.8 }}
                         animate={{ opacity: 1, scale: 1 }}
                         transition={{
                              duration: 1.5,
                              ease: 'easeInOut',
                         }}
                    >
                         {[...Array(3)].map((_, i) => (
                              <motion.div
                                   key={i}
                                   style={{
                                        width: '100px',
                                        height: '100px',
                                        background: 'radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,0,0,1) 50%, rgba(255,255,0,1) 100%)',
                                        borderRadius: '50%',
                                   }}
                                   animate={{
                                        scale: [1, 1.5, 1],
                                        opacity: [1, 0, 1],
                                        x: [0, 50, -50, 0],
                                        y: [0, -50, 50, 0],
                                   }}
                                   transition={{
                                        duration: 2,
                                        repeat: Infinity,
                                        repeatType: 'loop',
                                        delay: i * 0.5,
                                   }}
                              />
                         ))}
                    </motion.div> */}


          </div>



     )
}

export default NewYearBanner