import React, { useState, useContext } from "react";
import { TextField, Button, Container, Typography,IconButton, Grid, Box } from '@mui/material';
import { Context } from "../../Utils/ContextAPI/ContextProvider";
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


function FeedBack() {
  const [gState] = useContext(Context);
  const { user_id } = gState;
  const navigate = useNavigate();

  // Initial form state
  const initialFormData = {
    name: "",
    mobile: "",
    comments: "",
    address: "",
  };

  // Define state for form data
  const [formData, setFormData] = useState(initialFormData);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState('');

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const { name, mobile, comments, address } = formData;

    // Form validation: Check if any field is empty
    if (!name || !mobile || !comments || !address) {
      setError('Please fill out all fields.');
      return; // Stop form submission
    }

    // Create FormData object
    const data = new FormData();
    data.append("user_id", user_id); // Fetch user_id from global state
    Object.keys(formData).forEach((key) => {
      data.append(key, formData[key]);
    });

    try {
      // Send a POST request
      const response = await fetch(
        "https://shreddersbay.com/API/feedback_api.php?action=insert",
        {
          method: "POST",
          body: data,
        }
      );

      const result = await response.json();
      console.log(result);

      // Show success message if form submission is successful
      setOpen(true);
      setFormData(initialFormData); // Reset form after submission
      setError(''); // Clear error after successful submission

    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  // Handle Snackbar close
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    setError('');
    navigate('/scrap'); // Navigate after closing Snackbar
  };

  const handleBackClick = () => {
    navigate('/');
  };

  return (

    <Box>

<Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "10px",
          backgroundColor: "#f5f5f5",
          marginTop: 6,
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <IconButton onClick={handleBackClick} sx={{ marginLeft: "10px" }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography
          variant="h6"
          component="div"
          sx={{ fontWeight: "bold", marginLeft: "10px" }}
        >
         Feedback
        </Typography>
      </Box>

      <Container maxWidth="sm">

     

      
        <Box sx={{
          marginTop: { xs: 10, sm: 7, md: 10 },
          padding: { xs: 5, sm: 5, md: 3 },
          boxShadow: { xs: 3, sm: 3, md: 3 },
          borderRadius: { xs: 2, sm: 2, md: 2 },
        }}>
          <Typography variant="h5" gutterBottom>
            Feedback Form
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  fullWidth
                  required
                  error={!formData.name && !!error} // Show error if field is empty
                  helperText={!formData.name && error} // Show error message
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Mobile"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleInputChange}
                  fullWidth
                  required
                  error={!formData.mobile && !!error}
                  helperText={!formData.mobile && error}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Comments"
                  name="comments"
                  value={formData.comments}
                  onChange={handleInputChange}
                  fullWidth
                  required
                  error={!formData.comments && !!error}
                  helperText={!formData.comments && error}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Address"
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                  fullWidth
                  required
                  error={!formData.address && !!error}
                  helperText={!formData.address && error}
                />
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained"
                  sx={{
                    backgroundColor: '#00457E',  // Set background color
                    color: '#fff',               // Set text color
                    '&:hover': {                 // Optionally set hover state
                      backgroundColor: '#003366' // Darker shade on hover
                    }
                  }}
                  fullWidth type="submit">
                  Submit Feedback
                </Button>
                <Snackbar
                  open={open}
                  autoHideDuration={5000}
                  onClose={handleClose}
                  message="The Feedback has been successfully submitted"
                />
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </Box>

  );
}

export default FeedBack;
