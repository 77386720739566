import React, { useEffect, useState } from 'react';

function Learn() {



     const [number, setnumber] = useState('');
     const [result, setresult] = useState(null);
     const [numbers, setnumbers] = useState('');
     const [largestNumber, setlargestNumber] = useState(null);
     const [word, setword] = useState('');
     const [output, setOutput] = useState(null);
     const [capital, setCapital] = useState('');
     const [result1, setResult] = useState(null);
     const [input, setInput] = useState("");
     const [reversed, setReversed] = useState("");


     // Function to reverse a string
     const reverseString = () => {
          let input = "hello";
          let input2 = 30;
          // Correctly use split, reverse, and join to reverse the string
          // Used .split(',') to split the input string into an array.
          // reverse function is used for reverse the number and
          // join:- combines the reversed array back into a string.


          let reversed = input.split('').reverse().join('');
          console.log(reversed); // Logs "olleh"
          console.log(`the value is ${input2} !`);
     };

     const checkEvenOdd = () => {
          if (number % 2 == 0) {
               return "even";
          }
          else {
               return "odd";
          }

     }


     const handleCheck = () => {
          const output = checkEvenOdd(Number(number));
          setresult(output);
     }

     const CheckLargest = (arr) => {
          return Math.max(...arr);

          // Math.max(): This method returns the largest value from a list of numbers.
          // ... (spread operator): Expands the array elements into individual arguments for Math.max.


     }

     const handleCheckLargest = () => {
          // split method change the string into array and the .map function change the array in number
          // : If numbers = "2,5,50", the split(',') method will break the string into an array:
          let largerNumber = numbers.split(',').map(Number);
          let findlarge = CheckLargest(largerNumber);
          setlargestNumber(findlarge);

     }



     const handleConvertWord = () => {
          let Sentence = word.split(' ');
          setOutput(Sentence);
     }

     // Function to capitalize the first letter of each word
     const CapitalizeWord = (sentence) => {
          return sentence
               .split(' ') // Split the sentence into an array of words
               .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
               .join(' '); // Join the array back into a single sentence
     };

     // Handle button click to capitalize words
     const handleCapitalize = () => {
          const sentence = CapitalizeWord(capital); // Process the input sentence
          setResult(sentence); // Update the result state
     };


     const reverseWithoutFunction = (str) => {
          let reversed = "";
          for (let i = str.length - 1; i >= 0; i--) {
            reversed += str[i];
          }
          return reversed;
        };
      
        // Handle the button click to reverse the string
        const handleReverse = () => {
          const result = reverseWithoutFunction(input);
          setReversed(result); // Update the reversed state
        };
       



     return (
          <div>
               Learn
               {/* Correctly define inline styles */}
             
               <div style={{ padding: "20px" }}>
                    {/* Pass the function reference to onClick */}


                    {/* find the reverse value of variable */}
                    <div style={{ margin: 20 }}>

                         <button onClick={reverseString} style={{ fontSize: 20 }}>Reverse String</button>

                    </div>


                    {/* check the number is even or odd */}
                    <div style={{ margin: 20 }}>
                         <input type="text"
                              value={number}
                              onChange={(e) => setnumber(e.target.value)}
                              placeholder='enter the number'
                         />
                         <button onClick={handleCheck} style={{ fontSize: 20 }}>CheckEven/Odd</button>

                         <div style={{ fontSize: '30px', margin: 20 }}>
                              {/* The ${} syntax is used to interpolate (insert) the value of a variable or an expression into a string. */}
                              {/* Template Literals:

                              Template literals are enclosed with backticks (`) instead of quotes (' or ").
                              They allow for multi-line strings, variable interpolation, and embedding expressions.
                              */}
                              {result && `the number is ${result}.`}
                         </div>

                    </div>


                    {/* find the largest number in array */}
                    <div style={{ margin: 20 }}>
                         <input
                              type='text'
                              value={numbers}
                              onChange={(e) => setnumbers(e.target.value)}
                              placeholder='enter the Array Value'
                              style={{ marginInline: 60, fontSize: 20, width: "80%" }}
                         />



                         <button onClick={handleCheckLargest} style={{ fontSize: 20 }}>CheckLargest</button>

                         <div style={{ fontSize: 30, margin: 20 }}>

                              {
                                   largestNumber !== null && (
                                        <div>
                                             the Largest Number is :{largestNumber}
                                        </div>
                                   )
                              }

                         </div>
                    </div>

                    {/* Convert the string sentence to an array of words with inverted double quotes */}
                    <div style={{ margin: 20 }}>
                         <input type="text"
                              value={word}
                              onChange={(e) => setword(e.target.value)}
                              placeholder='enter the Sentence'
                              style={{ fontSize: 30, marginRight: 30, width: "80%" }}
                         />


                         <button onClick={handleConvertWord} style={{ fontSize: 20 }}> ConvertWord</button>

                         <div style={{ fontSize: 30, margin: 20 }}>
                              {
                                   output && `the sentence convert into word ${output.join('|')}`
                              }

                         </div>
                    </div>


                    {/* To Capitalize the first letter of each word in sentences */}
                    <div style={{ margin: 20 }}>
                         {/* Input field */}
                         <input
                              type="text"
                              value={capital}
                              onChange={(e) => setCapital(e.target.value)}
                              placeholder="Enter the sentence"
                              style={{ fontSize: 30, marginRight: 20, width: "80%" }}
                         />

                         {/* Button */}
                         <button
                              onClick={handleCapitalize}
                              style={{ fontSize: 20, padding: 10 }}
                         >
                              Capitalize Words
                         </button>

                         {/* Display the result */}
                         <div style={{ marginTop: 20, fontSize: 18 }}>
                              {result1 && `The sentence with capitalized words: ${result1}`}
                         </div>
                    </div>



                    {/* write a function to reverse a given string without using build-in methods like reverse(). */}
                    <div style={{ padding: "20px" }}>
                         <h2>Reverse String</h2>

                         <input
                              type="text"
                              value={input}
                              onChange={(e) => setInput(e.target.value)}
                              placeholder="Enter a string"
                              style={{ padding: "5px", marginRight: "10px" , fontSize:30}}
                         />


                         <button onClick={handleReverse} style={{ padding: "5px 10px", fontSize:30 }}>
                              Reverse
                         </button>


                         {reversed && (
                              <div style={{ marginTop: "20px" , fontSize:30}}>
                                   <h5>Reversed String:</h5>
                                   <p>{reversed}</p>
                              </div>
                         )}
                    </div>




               </div>
          </div>
     );
}

export default Learn;
