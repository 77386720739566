
import { app } from "../Utils/fire_base/firebase_setup";
import { createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword } from "firebase/auth";
// import { getDatabase, ref, set } from "firebase/database";
import { getDatabase, ref, update, onValue, set,get } from 'firebase/database';
import { getAuth } from 'firebase/auth';

const auth = getAuth();

const create_user_in_firebase = (firebase_uid, email, name = "", mobile = "" ) => {
     const db = getDatabase();
   
     // Check if the mobile number has 10 digits and set verify to "true"
     let verify = (mobile.length === 10) ? "true" : "false";

   
     // Set user data in Firebase Realtime Database
     set(ref(db, 'users/' + firebase_uid), {
       email: email,
       username: name,
       phone_number: mobile,
       verify
     });
   };
   
// const create_user_in_firebase = (firebase_uid, email, name = "", mobile = "", verify = "false") => {
//      const db = getDatabase();
//      if(mobile.length == 10){
//           verify == "true";
//      }
//      set(ref(db, 'users/' + firebase_uid), {
//           email: email,
//           username: name,
//           // userId: firebase_uid,

//           phone_number: mobile,
//           verify
//      });
// }
// const get_user_data = async (firebase_uid) => {
//      console.log("Fetching data for UID:", firebase_uid);
//      const db = getDatabase();
//      const userRef = ref(db, 'users/' + firebase_uid);

//      try {
//           const snapshot = await get(userRef);
//           if (snapshot.exists()) {
//                const userData = snapshot.val();
//                console.log("User Data: ", userData);
//                return userData;
//           } else {
//                console.log("No data available for this user.");
//                return null;
//           }
//      } catch (error) {
//           console.error("Error fetching user data: ", error);
//           return null;
//      }
// };

const get_user_data = async (firebase_uid) => {
     const db = getDatabase();
     const userRef = ref(db, 'users/' + firebase_uid);
   
     console.log("Fetching data for UID:", firebase_uid); // Debugging line
   
     try {
       const snapshot = await get(userRef);
       if (snapshot.exists()) {
         const userData = snapshot.val();
         console.log("User Data: ", userData);
         return userData;
       } else {
         console.log("No data available for this user.");
         return null;
       }
     } catch (error) {
       console.error("Error fetching user data: ", error);
       return null;
     }
   };
   




const create_product_in_firebase = async (
  firebase_pid,
  firebase_uid,
  productId,
  title,
  description,
  price,
  weight,
  imageUrl = "https://cdn-icons-png.flaticon.com/512/3237/3237472.png"
) => {
  try {
    const db = getDatabase();
    
    // Set the firebase_pid as the key in the database path
    const productRef = ref(db, 'product/' + firebase_pid);

    // Post the product data to Firebase under the firebase_pid key
    await set(productRef, {
      firebase_pid, // Including firebase_pid in the data as well
      title,
      description,
      price,
      weight,
      image: imageUrl,
      userId: firebase_uid,
      productId,
    });

    console.log("Product created successfully");

    // After posting, get the product data from Firebase
    const snapshot = await get(productRef);
    
    if (snapshot.exists()) {
      const productData = snapshot.val();
      console.log("Retrieved product data:", productData);

      // Return everything in an object for easier navigation
      return {
        success: true,
        message: "Product created and data retrieved successfully",
        product: {
          [firebase_pid]: productData // The object with firebase_pid as the key
        }
      };
    } else {
      console.log("No data available");
      return {
        success: false,
        message: "Product created, but no data found",
        product: null
      };
    }
  } catch (error) {
    console.error("Error creating or retrieving product:", error);
    return {
      success: false,
      message: `Error: ${error.message}`,
      product: null
    };
  }
};



function generateUniqueMessageId() {
     const timestamp = Date.now().toString(36); // Convert timestamp to base-36 (0-9, a-z)
     const randomPart = Math.random().toString(36).substring(2, 10); // Generate a random alphanumeric string
     return `${timestamp}-${randomPart}`;
}


function generateUniqueId() {
     const timestamp = Date.now().toString(36); // Convert timestamp to base-36 (0-9, a-z)
     const randomPart = Math.random().toString(36).substring(2, 10); // Generate a random alphanumeric string
     return `${timestamp}-${randomPart}`;
}

function sendMessage(message, senderID, receiverID, productId) {
     const db = getDatabase();
     const messageId = generateUniqueMessageId(); // Implement this function to generate unique IDs
     const messageRef = ref(db, 'messages/' + messageId);

     set(messageRef, {
          senderId: senderID,
          receiverId: receiverID,
          content: message,
          timestamp: Date.now(),
          status: 'sent',
          productId: productId,

     }).then(() => {
          console.log('Message sent!');
     }).catch((error) => {
          console.error('Error sending message:', error);
     });
}

function markMessageAsDelivered(messageId) {
     const db = getDatabase();
     const messageRef = ref(db, 'messages/' + messageId);

     update(messageRef, {
          status: 'delivered'
     }).then(() => {
          console.log('Message marked as delivered!');
     }).catch((error) => {
          console.error('Error updating message status:', error);
     });
}

function markMessageAsSeen(messageId) {
     const db = getDatabase();
     const messageRef = ref(db, 'messages/' + messageId);

     update(messageRef, {
          status: 'seen'
     }).then(() => {
          console.log('Message marked as seen!');
     }).catch((error) => {
          console.error('Error updating message status:', error);
     });
}

function listenForMessageStatusChanges(messageId) {
     const db = getDatabase();
     const messageRef = ref(db, 'messages/' + messageId);

     onValue(messageRef, (snapshot) => {
          const messageData = snapshot.val();
          console.log('Message data:', messageData);

          if (messageData) {
               switch (messageData.status) {
                    case 'sent':
                         console.log('Message sent');
                         break;
                    case 'delivered':
                         console.log('Message delivered');
                         break;
                    case 'seen':
                         console.log('Message seen');
                         break;
                    default:
                         console.log('Unknown status');
               }
          }
     });
}

//    use demo chat

//    useEffect(() => {
//      // Listen for message status changes
//      listenForMessageStatusChanges(messageId);

//      // Simulate sending a message
//      sendMessage('user1', 'user2', 'Hello!');

//      // Simulate marking message as delivered after some time
//      setTimeout(() => markMessageAsDelivered(messageId), 5000);

//      // Simulate marking message as seen after some more time
//      setTimeout(() => markMessageAsSeen(messageId), 10000);
//    }, []);

const update_user_in_firebase = (firebase_uid, email, name = "", mobile = "") => {
  const db = getDatabase();
  const updates = {};

  // Set verify based on mobile number length
  let verify = (mobile.length === 10) ? "true" : "false";

  // Add fields to update
  if (email) {
    updates['email'] = email;
  }
  if (name) {
    updates['username'] = name;
  }
  if (mobile) {
    updates['phone_number'] = mobile;
  }
  // Add verify to updates
  updates['verify'] = verify;

  // Update the specific fields for the user
  update(ref(db, 'users/' + firebase_uid), updates)
    .then(() => {
      console.log('User data updated successfully.');
    })
    .catch((error) => {
      console.error('Error updating user data:', error);
    });
};


const firebaseSignIn = (email, password, callMe = () => console.log("Default callback executed")) => {
     signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
               const user = userCredential.user;

               if (user.emailVerified) {
                    console.log('User is signed in and email is verified:', user);
                    alert('User SignIn Successfully!');
                    callMe();
               }

               else {
                    console.log('Email is not verified');
                    alert('Please verify your email before signing in.');
               }
          })
          .catch((error) => {
               // setError(error.message);
               console.error('SignIn error:', error);
          });
}

const firebaseSignup = async (email, password, handleMe = (num) => console.log(`this is default callback ${num}`)) => {
     try {
          // Create user with email and password
          const userCredential = await createUserWithEmailAndPassword(auth, email, password);
          const user = userCredential.user;
          console.log("firebase user id is =>", user.uid);
          // setFirbaseUID(user.uid);
          // Send verification email
          console.log("user=>", user);
          await sendEmailVerification(user);
          handleMe(user.uid);

          alert('Verification email sent. Please check your inbox.');

     } catch (error) {
          console.error("Error signing up:", error.message);
          alert(`Error: ${error.message}`);
     }
}
const firebaseSignupwithGoogle = async (email, password, handleMe = (num) => console.log(`this is default callback ${num}`)) => {
     try {
          // Create user with email and password
          const userCredential = await createUserWithEmailAndPassword(auth, email, password);
          const user = userCredential.user;
          console.log("firebase user id is =>", user.uid);
          // setFirbaseUID(user.uid);
          // Send verification email
          console.log("user=>", user);
          return user.uid;
          // await sendEmailVerification(user);
     } catch (error) {
          console.error("Error signing up:", error.message);
          alert(`Error: ${error.message}`);
     }
}

const firebaseSignIn_google = (email, password, callMe = () => console.log("Default callback executed")) => {
     signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
               const user = userCredential.user;

               // if (user.emailVerified) {
               // console.log('User is signed in and email is verified:', user);
               alert('User SignIn Successfully!');
               callMe();
               // }

               // else {
               //      console.log('Email is not verified');
               //      alert('Please verify your email before signing in.');
               // }
          })
          .catch((error) => {
               // setError(error.message);
               console.error('SignIn error:', error);
          });
}


const fetchUserDetails = async (bfirebaseUID) => {
     const db = getDatabase();

     const userRef = ref(db, `users/${bfirebaseUID}`);
     try {
         const snapshot = await get(userRef);
         if (snapshot.exists()) {
          //    setUserDetails(snapshot.val());
          return snapshot.val()
         } else {
             console.log("No user data available");
         }
     } catch (error) {
         console.error("Error fetching user data:", error);
     }
 };

 const fetchProductDetails = async (bfirebaseProdID) => {
     const db = getDatabase();

     const productRef = ref(db, `product/${bfirebaseProdID}`);
     try {
         const snapshot = await get(productRef);
         if (snapshot.exists()) {
          //    setProductDetails(snapshot.val());
          return snapshot.val();
         } else {
             console.log("No product data available");
         }
     } catch (error) {
         console.error("Error fetching product data:", error);
     }
 };
export {
     fetchUserDetails,
     fetchProductDetails,
     create_user_in_firebase,
     create_product_in_firebase,
     sendMessage,
     markMessageAsDelivered,
     markMessageAsSeen,
     listenForMessageStatusChanges,
     firebaseSignIn, firebaseSignup,
     firebaseSignupwithGoogle,
     firebaseSignIn_google,
     get_user_data,
     update_user_in_firebase,
     generateUniqueMessageId,
     generateUniqueId
}