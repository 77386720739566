import React from 'react';
import { Box, TextField, Button, IconButton, Typography, Grid, Paper } from '@mui/material';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";



function Contact() {

     const navigate = useNavigate();

     const handleSubmit = (e) => {
          e.preventDefault();
          alert('Message sent! We will get back to you shortly.');
     };


     const handleBackClick = () => {
          navigate("/");
     };
     return (
          <Box>
               <Box
                    sx={{
                         display: "flex",
                         alignItems: "center",
                         padding: "10px",
                         backgroundColor: "#f5f5f5",
                         marginTop: 6,
                         boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    }}
               >
                    <IconButton onClick={handleBackClick} sx={{ marginLeft: "10px" }}>
                         <ArrowBackIcon />
                    </IconButton>
                    <Typography
                         variant="h6"
                         component="div"
                         sx={{ fontWeight: "bold", marginLeft: "10px" }}
                    >
                         Contact Us
                    </Typography>
               </Box>
               <Box
                    sx={{

                         margin: { xs: 6, sm: 4, md: 4 }
                    }}>



                    {/* Description Section */}
                    <Box sx={{ textAlign: 'center', marginBottom: 4 }}>

                         <Typography
                              variant="subtitle1"
                              sx={{ marginTop: 2, color: '#555', maxWidth: '700px', marginX: 'auto' }}
                         >
                              We value your feedback and inquiries! Whether you have a question, need support,
                              or want to give us feedback, feel free to reach out to us using the form below.
                              Our team will get back to you as soon as possible.
                         </Typography>
                    </Box>

                    {/* Contact Form Section */}
                    <Grid container spacing={4} justifyContent="center">
                         {/* Google Maps Section */}

                         {/* Additional Contact Details Section */}
                         <Grid item xs={12} md={6} >
                              <Paper elevation={3} sx={{ padding: 4 }}>
                                   <Typography
                                        variant="h6"
                                        sx={{ fontWeight: 'bold', color: '#333', marginBottom: 2 }}
                                   >
                                        Contact Information
                                   </Typography>
                                   <Typography
                                        variant="body1"
                                        sx={{
                                             marginBottom: 1,
                                             fontWeight: 'bold',
                                             color: '#4CAF50',
                                        }}
                                   >
                                        Email:
                                   </Typography>
                                   <Typography
                                        variant="body2"
                                        sx={{ marginBottom: 2, color: '#555' }}
                                   >
                                        <a href="mailto:yourcompanyemail@gmail.com">
                                            shreddersbaydeals@gmail.com
                                        </a>
                                   </Typography>

                                   <Typography
                                        variant="body1"
                                        sx={{
                                             marginBottom: 1,
                                             fontWeight: 'bold',
                                             color: '#4CAF50',
                                        }}
                                   >
                                        Call Us:
                                   </Typography>
                                   <Typography
                                        variant="body2"
                                        sx={{ marginBottom: 2, color: '#555' }}
                                   >
                                        <a href="tel:+918080961448">+918080961448</a>
                                   </Typography>

                                   <Typography
                                        variant="body1"
                                        sx={{
                                             marginBottom: 1,
                                             fontWeight: 'bold',
                                             color: '#4CAF50',
                                        }}
                                   >
                                        WhatsApp:
                                   </Typography>
                                   <Typography
                                        variant="body2"
                                        sx={{ color: '#555' }}
                                   >
                                        <a href="https://wa.me/1234567890" target="_blank" rel="noopener noreferrer">
                                             Message Us on WhatsApp
                                        </a>
                                   </Typography>
                              </Paper>
                         </Grid>

                         {/* Google Maps Section */}
                         <Grid item xs={12} md={6}>

                              <Box
                                   sx={{
                                        border: '1px solid lightgray',
                                        borderRadius: '8px',
                                        overflow: 'hidden',
                                        boxShadow: '0px 4px 10px rgba(0,0,0,0.2)',
                                   }}
                              >
                                   <iframe
                                        title="Google Maps Location"
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8354345094276!2d144.95565131531683!3d-37.81732797975171!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf577f05f7be3c2c2!2sFederation%20Square!5e0!3m2!1sen!2sau!4v1634527780476!5m2!1sen!2sau"
                                        width="100%"
                                        height="300"
                                        style={{ border: 0 }}
                                        allowFullScreen=""
                                        loading="lazy"
                                   ></iframe>
                              </Box>
                         </Grid>
                    </Grid>
               </Box>
          </Box>
     );
}

export default Contact;
