import React, { useState, useEffect } from 'react';
import { Fab } from '@mui/material'; // Material-UI Floating Action Button
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function ScrollToTop() {
     const [isVisible, setIsVisible] = useState(false);

      // Track scroll position
  useEffect(() => {
     const toggleVisibility = () => {
       if (window.scrollY > 300) { // Show the button after scrolling 300px
         setIsVisible(true);
       } else {
         setIsVisible(false);
       }
     };
 
     window.addEventListener('scroll', toggleVisibility);
 
     return () => {
       window.removeEventListener('scroll', toggleVisibility);
     };
   }, []);
 
   // Scroll to the top
   const scrollToTop = () => {
     window.scrollTo({
       top: 0,
       behavior: 'smooth', // Smooth scrolling
     });
   };

  return (
     <>
     {isVisible && (
       <Fab
         color="primary"
         size="medium"
         onClick={scrollToTop}
         sx={{
           position: 'fixed',
           bottom: 16,
           right: 16,
           backgroundColor: '#00457E',
           '&:hover': {
             backgroundColor: '#003566',
           },
         }}
       >
         <KeyboardArrowUpIcon />
       </Fab>
     )}
   </>
  )
}

export default ScrollToTop