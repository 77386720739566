import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Category from './pages/Home/Category';
import Slider from './Components/Cred/Slider';
import Getorder from './pages/Home/Getorder';
import Getauction from './pages/Home/Getauction';
import Navigation from './pages/Navigation';
import MySell from './pages/Sell/MySell';
import MultiplePhoto from './pages/Sell/MultiplePhoto';
import ProductForm from './pages/Sell/ProductForm';
import Orderdetails from './pages/Home/Orderdetails';
import CurrentOrder from './pages/Home/CurrentOrder';
import Footer from './pages/Home/Footer';
import AuctionOrderdetail from './pages/Home/AuctionOrderdetail';
import TestFire from './pages/TestComp/TestFire';
import ChatInbox from './pages/Chat/ChatInbox';
import MyAddress from './pages/Home/MyAddress';
import OrderAuctionDetail from './pages/Myorder/OrderAuctionDetail';
import OrderSellDetail from './pages/Myorder/OrderSellDetail';
import CustomModalForgotPassword from './pages/Home/CustomModalForgotPassword';
import FeedBack from './pages/Home/Feedback';
import UpdateProfile from './pages/Home/UpdateProfile';
import Rating from './pages/Home/Rating';
import OrderSearch from './pages/Home/OrderSearch';
import AuctionCurrentComDetail from './pages/Myorder/AuctionCurrentComDetail';
import { requestFirebaseNotificationPermission } from './Utils/fire_base/firebase_setup';
import { Context } from './Utils/ContextAPI/ContextProvider'; // Import your context
import Learn from './pages/Home/Learn';
import Faq from './pages/Home/Faq';
import Contact from './pages/Home/Contact';
import ScrollToTop from './pages/Home/ScrollToTop';
import NewYearBanner from './pages/Home/NewYearBanner';
import Republicday from './pages/Home/Republicday';

function App() {
  const [gState] = useContext(Context); // Access global state from Context API
  const { user_id } = gState; // Destructure user_id from global state

  useEffect(() => {
  const getNotificationToken = async () => {
    const token = await requestFirebaseNotificationPermission();
    if (token) {
      console.log('Firebase Token:', token);
      sendTokenToServer(token);
    }
  };

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
      .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope);
        getNotificationToken();
      })
      .catch((error) => {
        console.error('Service Worker registration failed:', error);
      });
  }
}, []); // Empty dependency array


  // Function to send FCM token and user_id to the server
  async function sendTokenToServer(token) {
    if (!user_id) {
      console.error('User ID not found');
      return;
    }

    try {
      // Create a new FormData object to send user_id and FCM token
      const formData = new FormData();
      formData.append('user_id', user_id); // Dynamically append user_id from context
      formData.append('fcm_token', token); // Append FCM token

      // Send a POST request to the PHP backend to save the token
      const response = await fetch('https://shreddersbay.com/API/save_token.php?action=save_token', {
        method: 'POST',
        body: formData, // Send FormData in the body
      });

      const result = await response.json(); // Parse JSON response
      console.log('Server response:', result);

    } catch (error) {
      console.error('Error sending token to server:', error);
    }
  }

  return (
    <Router>
      <div className="App">
        <Navigation />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <ScrollToTop/>
                {/* <NewYearBanner/> */}
                {/* <Republicday/> */}
                <Slider />
                <Category />
                <Getorder />
                <Getauction />
                <Footer />
              </>
            }
            exact
          />
          <Route path="/sell" element={<MySell />} />
          <Route path="/multiplephoto" element={<MultiplePhoto />} />
          <Route path="/productform" element={<ProductForm />} />
          <Route path="/orderdetails" element={<Orderdetails />} />
          <Route path="/Currentorder" element={<CurrentOrder />} />
          <Route path="/auctiondetail" element={<AuctionOrderdetail />} />
          <Route path="/test" element={<TestFire />} />
          <Route path="/chatinbox" element={<ChatInbox />} />
          <Route path="/myaddress" element={<MyAddress />} />
          <Route path="/orderauctiondetail" element={<OrderAuctionDetail />} />
          <Route path="/orderSelldetail" element={<OrderSellDetail />} />
          <Route path="/forgotpassword" element={<CustomModalForgotPassword />} />
          <Route path="/feedback" element={<FeedBack />} />
          <Route path="/profile/:user_id" element={<UpdateProfile />} />
          <Route path="/reviews" element={<Rating />} />
          <Route path="/result" element={<OrderSearch />} />
          <Route path="/auctionCurrentComDetail" element={<AuctionCurrentComDetail />} />
          <Route path="/learn" element={<Learn/>}/>
          <Route path="/faq" element={<Faq/>}/>
          <Route path='contact' element={<Contact/>}/>
          <Route path="/newyear" element={<NewYearBanner/>}/>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
