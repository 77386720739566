import React, { useState, useEffect, useContext } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import {  IconButton,
} from "@mui/material";

import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import { ImagesApp } from '../../Images/ImagesApp';
import { useNavigate, } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Color } from '../../Constants/Color';
import { Context } from '../../Utils/ContextAPI/ContextProvider';


function OrderAuctionDetail() {

     const theme = useTheme();
     const [gState] = useContext(Context);
     const { user_id } = gState;
     const [value, setValue] = React.useState(0);
     const [auctionData, setAuctionData] = useState([]);

     const navigate = useNavigate();



     const handleChange = (event, newValue) => {
          setValue(newValue);
     };


     function TabPanel(props) {
          const { children, value, index, ...other } = props;

          return (
               <div
                    role="tabpanel"
                    hidden={value !== index}
                    id={`full-width-tabpanel-${index}`}
                    aria-labelledby={`full-width-tab-${index}`}
                    {...other}
               >
                    {value === index && (
                         <Box sx={{ p: 3 }}>
                              <Typography>{children}</Typography>
                         </Box>
                    )}
               </div>
          );
     }

     TabPanel.propTypes = {
          children: PropTypes.node,
          index: PropTypes.number.isRequired,
          value: PropTypes.number.isRequired,
     };

     function a11yProps(index) {
          return {
               id: `full-width-tab-${index}`,
               'aria-controls': `full-width-tabpanel-${index}`,
          };
     }



     const navigateCurrentCompleteBuy = () => {
          navigate('/currentorder');
     }

     const navigateCurrentCompleteSell = () => {
          navigate('/orderselldetail');
     }

     const navigateCurrentCompleteAuction = () => {
          navigate('/auctionCurrentComDetail');
     }

     const handleBackClick = () => {
          navigate('/');
     };
     return (
          <Box>
              <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "10px",
          backgroundColor: "#f5f5f5",
          marginTop: 6,
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <IconButton onClick={handleBackClick} sx={{ marginLeft: "10px" }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography
          variant="h6"
          component="div"
          sx={{ fontWeight: "bold", marginLeft: "10px" }}
        >

          MyBooking
        </Typography>
      </Box>
               <Box sx=
                    {{
                         bgcolor: 'background.paper',
                         width: { xs: '90%', sm: '80%', md: 800 },
                         margin: 'auto',
                         marginTop: {xs:5,md:5},
                         border: 2,
                         borderColor: "#eee",
                         padding: { xs: '0%', sm: '0%', md: 10 }
                    }}>


                    <AppBar position="static">
                         <Tabs
                              value={value}
                              onChange={handleChange}
                              indicatorColor="secondary"
                              textColor="inherit"
                              variant="fullWidth"
                              aria-label="full width tabs example"
                         >
                              <Tab label="Order" {...a11yProps(0)} sx={{ backgroundColor: '#00457E', color: '#fff' }} />
                              <Tab label="Auction" {...a11yProps(1)} sx={{ backgroundColor: '#00457E', color: '#fff' }} />

                         </Tabs>
                    </AppBar>


                    <TabPanel value={value} index={0} dir={theme.direction}>
                         <Box sx={{
                              width: { xs: '100%', sm: '100%', md: '100%' }
                         }}>
                              <Box sx={{
                                   width: { xs: '100%', sm: '100%', md: '100%' },  // Full width on mobile, 50% on larger screens
                                   display: 'flex',
                                   justifyContent: 'center',
                                   flexDirection: { xs: 'column', sm:'column', md: 'row' },  // Stack images vertically on mobile, horizontally on desktop
                                   alignItems: 'center',  // Center items vertically
                              }}>
                                   <Box sx={{ width: { xs: '100%',sm:'100%', md: '100%' }, 
                                             alignItems:'center',
                                             justifyContent:'center', }}
                                        onClick={navigateCurrentCompleteBuy}
                                   >  {/* Full width on mobile, 50% on desktop */}
                                        <img src={ImagesApp.vectorBuy} alt="Desktop Shreddersbay" style={{ maxWidth: '100%', height: 'auto' }} />
                                   </Box>

                                   <Box sx={{ width: { xs: '100%', md: '100%' }, textAlign: 'center' }}
                                        onClick={navigateCurrentCompleteSell}
                                   >  {/* Full width on mobile, 50% on desktop */}
                                        <img src={ImagesApp.vectorSell} alt="Desktop Shreddersbay" style={{ maxWidth: '100%', height: 'auto' }} />
                                   </Box>
                              </Box>
                         </Box>


                    </TabPanel>


                    <TabPanel value={value} index={1} dir={theme.direction}>

                         <Box sx={{
                              width: { xs: '100%', sm: '80%', md: '100%' },
                              padding: 0,
                         }}>
                              <Box sx={{
                                   width: { xs: '100%', sm: '80%', md: '100%' },  // Full width on mobile, 50% on larger screens
                                   display: 'flex',
                                   justifyContent: 'center',
                                   flexDirection: { xs: 'column', md: 'row' },  // Stack images vertically on mobile, horizontally on desktop
                                   alignItems: 'center',  // Center items vertically
                                   padding: 0,

                              }}>
                                   <Box sx={{
                                        width: { xs: '100%', md: '100%' },
                                        textAlign: 'center',
                                   }}
                                        onClick={navigateCurrentCompleteAuction}
                                   >  {/* Full width on mobile, 50% on desktop */}
                                        <img src={ImagesApp.auction} alt="Desktop Shreddersbay" style={{ width: '100%', maxWidth: '100%', height: '50%' }} />
                                   </Box>


                              </Box>
                         </Box>

                    </TabPanel>

               </Box>
          </Box>

     )
}

export default OrderAuctionDetail